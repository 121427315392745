import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/components/layout/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/components/layout/Navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/components/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/font/local/target.css?{\"path\":\"assets/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./ClashDisplay/ClashDisplay-Regular.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./ClashDisplay/ClashDisplay-Medium.otf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./ClashDisplay/ClashDisplay-Semibold.otf\",\"weight\":\"600\",\"style\":\"normal\"}],\"variable\":\"--font-clash-display\"}],\"variableName\":\"clashDisplay\"}");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/static/globals.css");
