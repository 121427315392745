import { IconProps } from '@/static/types';

function Logo({ className }: IconProps) {
  return (
    <svg
      width='122'
      height='19'
      viewBox='0 0 122 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}>
      <path
        d='M3.36879 9.94386C3.36879 6.74686 5.8147 4.29852 8.65942 4.29852C10.3735 4.29852 11.7658 5.02748 12.7023 6.24052H16.5323C15.2344 3.1829 12.4377 0.956482 8.65942 0.956482C3.8146 0.956482 0.0269165 5.05943 0.0269165 9.94386C0.0269165 14.8261 3.80565 18.9312 8.65256 18.9312C11.2427 18.9312 13.4309 18.0136 14.9692 16.4169C16.4965 14.8316 17.2782 12.6864 17.2782 10.4069V8.7359H8.65942V12.0779H13.7082C13.4786 12.8719 13.0841 13.5567 12.5626 14.0981C11.7142 14.9787 10.425 15.5892 8.65256 15.5892C5.80993 15.5892 3.36879 13.1431 3.36879 9.94386Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.9827 10.3119L18.9827 18.6177H22.3048V8.65073H25.8643V5.32842H23.9659C21.2137 5.32842 18.9827 7.55959 18.9827 10.3119Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.2233 8.41033C31.4605 8.41033 29.9011 9.93035 29.9011 11.97C29.9011 14.0095 31.4605 15.5296 33.2233 15.5296C34.9861 15.5296 36.5454 14.0095 36.5454 11.97C36.5454 9.93035 34.9861 8.41033 33.2233 8.41033ZM26.579 11.97C26.579 8.24283 29.4818 5.08801 33.2233 5.08801C36.9648 5.08801 39.8676 8.24283 39.8676 11.97C39.8676 15.6971 36.9648 18.8519 33.2233 18.8519C29.4818 18.8519 26.579 15.6971 26.579 11.97Z'
        fill='currentColor'
      />
      <path
        d='M52.9629 17.2745C53.6575 19.4409 56.7074 19.4829 57.4614 17.3365L61.6567 5.32878H58.1355L55.2636 14.5184L53.0383 6.75269C52.3336 4.55468 49.2238 4.55468 48.519 6.75269L46.3127 14.4592L43.5416 5.32878H40.0296L44.0782 17.3144C44.819 19.475 47.8852 19.4442 48.5825 17.2693L50.7787 9.27564L52.9629 17.2745Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.1225 2.4809H66.4446V5.32859H70.0041V8.65091H66.4446V15.2955H70.0041V18.6179H68.1057C65.3536 18.6179 63.1225 16.3867 63.1225 13.6344V2.4809Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M71.9006 0.938354H75.2227V5.32856H78.5449C81.297 5.32856 83.5281 7.55974 83.5281 10.312V18.6178H80.2059V8.65088H75.2227V18.6178H71.9006V0.938354Z'
        fill='currentColor'
      />
      <path
        d='M97.132 6.2174C101.709 10.7943 98.4673 18.6202 91.9948 18.6202H89.4556V12.7343H95.3251L83.528 0.931213H91.8515L97.132 6.2174Z'
        fill='currentColor'
      />
      <path
        d='M100.666 13.3368C96.0892 8.75991 99.3307 0.934044 105.803 0.934045L121.886 0.934044V6.81992H102.473L114.273 18.6201H105.949L100.666 13.3368Z'
        fill='currentColor'
      />
    </svg>
  );
}

export default Logo;
